import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import _ from "lodash";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
// import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Search from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import PartImage from "./PartImage";
import { CompleteProducts } from "./queries.gql";
import "./index.css";

const styles = theme => ({
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1000,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block",
    zIndex: 1000
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    zIndex: 100
  },
  autosuggest: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 376
    }
  },
  input: {
    backgroundColor: "white",
    padding: "13px 14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 11
    }
  },
  textInputWrapper: {
    display: "flex"
  },
  iconButton: {
    background: "rgb(85, 85, 85)",
    borderRadius: "4px",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      background: "white",
      color: "rgb(85, 85, 85)"
    }
  },
  textField: {
    [`& fieldset`]: {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0
    }
  },
  listImage: {
    maxHeight: 25
  }
});

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <div className={classes.textInputWrapper}>
      <TextField
        className={classes.textField}
        fullWidth
        variant="outlined"
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input
          }
        }}
        {...other}
      />
      <Button type="submit" className={classes.iconButton}>
        <Search />
      </Button>
    </div>
  );
}

function renderSuggestion(classes, suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.productName, query);
  const parts = parse(suggestion.productName, matches);

  return (
    <MenuItem
      selected={isHighlighted}
      onClick={() => {
        // this could be improved
        window.location = `//mpparts.com/part/${suggestion.slug}`;
      }}
      component="div"
    >
      <ListItemAvatar>
        <PartImage node={suggestion} size={75} className={classes.listImage} />
      </ListItemAvatar>
      <ListItemText>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      </ListItemText>
      {/*</Grid>*/}
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.productDisplayName;
}

class AutocompleteSearch extends React.Component {
  state = {
    term: "",
    suggestions: []
  };

  handleSuggestionsFetchRequested = async ({ value }) => {
    const { client } = this.props;
    const { data } = await client.query({
      query: CompleteProducts,
      variables: { prefix: value }
    });
    this.setState({
      suggestions: _.map(
        _.get(data, "completeProducts.edges", []),
        ({ node }) => node
      )
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue
    });
  };

  getPopperWidth = () => {
    if (!this.popperNode) {
      return null;
    }
    return this.popperNode.clientWidth + 65;
  };

  getPopperOffset = () => {
    return 33;
  };

  render() {
    const { classes } = this.props;
    const { term } = this.state;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion: (suggestion, { query, isHighlighted }) =>
        renderSuggestion(classes, suggestion, { query, isHighlighted })
    };

    return (
      <Autosuggest
        {...autosuggestProps}
        className="autosuggest"
        inputProps={{
          classes,
          placeholder: "Search part number or description",
          value: term,
          onChange: this.handleChange("term"),
          name: "w",
          inputRef: node => {
            this.popperNode = node;
          },
          InputLabelProps: {
            shrink: true
          }
        }}
        theme={{
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Popper
            style={{ zIndex: 1000 }}
            anchorEl={this.popperNode}
            open={Boolean(options.children)}
            modifiers={{
              offset: {
                offset: this.getPopperOffset(),
                enabled: true
              }
            }}
          >
            <Paper
              square
              {...options.containerProps}
              style={{
                width: this.getPopperWidth(),
                zIndex: 1000
              }}
            >
              {options.children}
            </Paper>
          </Popper>
        )}
      />
    );
  }
}

AutocompleteSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired
};

export default withWidth()(withStyles(styles)(withApollo(AutocompleteSearch)));
