import React, { useState } from "react";
import PropTypes from "prop-types";
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo-hooks";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Search from "./Search";

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_ROOT || "http://localhost:5000/graphql"
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

const styles = theme => ({
  searchApp: {
    overflowX: "hidden"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

function App({ query, includeSearchInput, classes }) {
  const [currentQuery, setQuery] = useState(query);
  const [submittedQuery, setSubmittedQuery] = useState(query);
  return (
    <ApolloProvider client={client}>
      <CssBaseline />
      <div id="SearchApp" className={classes.searchApp}>
        <Grid container direction="row" justify="center" alignItems="center">
          {includeSearchInput ? (
            <Grid item xs={12}>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  setSubmittedQuery(currentQuery);
                }}
              >
                <TextField
                  id="standard-search"
                  label="Search For Parts"
                  type="search"
                  className={classes.textField}
                  margin="normal"
                  value={currentQuery}
                  onChange={e => setQuery(e.target.value)}
                />
              </form>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Search query={submittedQuery} />
          </Grid>
        </Grid>
      </div>
    </ApolloProvider>
  );
}

App.propTypes = {
  query: PropTypes.string,
  includeSearchInput: PropTypes.bool,
  classes: PropTypes.object
};

export default withStyles(styles)(App);
