import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import PartImage from "./PartImage";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

function SearchResultClassic({ node }) {
  return (
    <Grid item className="col-6 col-lg-3 g-mb-30" xs={6} md={3}>
      <figure className="g-pos-rel g-mb-20">
        <a href={`https://mpparts.com/part/${node.slug}`}>
          <PartImage node={node} size={300} />
        </a>
      </figure>
      <div className="media">
        <div className="d-flex flex-column" style={{ width: "100%" }}>
          <h4 className="h6 g-color-black mb-1">
            <a
              className="u-link-v5 g-color-black g-color-primary--hover g-font-size-17"
              href={`https://mpparts.com/part/${node.slug}`}
            >
              {node.productName}
            </a>
          </h4>
          <a
            className="d-inline-block g-color-gray-dark-v5 g-font-size-13"
            href={`https://mpparts.com/part/${node.slug}`}
          >
            {`Part Number: ${node.alias}`}
          </a>
          <form action="https://mpparts.com/cart" method="post">
            <input name="prod" type="hidden" value={node.prodid} />
            <input name="alias" type="hidden" value={node.alias} />
            <input name="nav" type="hidden" value="0" />
            <div className="g-mt-2">
              <span className="g-color-red g-font-size-17 g-mt-2">
                {node.unitPrice > 0
                  ? `${formatter.format(node.unitPrice)}`
                  : "CALL FOR PRICING"}
              </span>
              <button
                className=" btn btn-sm u-btn-red g-brd-2 g-ml-20"
                type="submit"
              >
                Add to Cart
              </button>
            </div>
          </form>
        </div>
      </div>
    </Grid>
  );
}

SearchResultClassic.propTypes = {
  node: PropTypes.object.isRequired
};

export default SearchResultClassic;
