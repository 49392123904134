import React from "react";
import PropTypes from "prop-types";
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import AutocompleteSearch from "./AutocompleteSearch";

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_ROOT || "http://localhost:5000/graphql"
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  autocompleteApp: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      width: "85%"
    },
    [theme.breakpoints.up("md")]: {
      width: 400
    }
  }
});

function AutocompleteApp({ classes }) {
  return (
    <ApolloProvider client={client}>
      <CssBaseline />
      <div id="AutocompleteApp" className={classes.autocompleteApp}>
        <AutocompleteSearch />
      </div>
    </ApolloProvider>
  );
}

AutocompleteApp.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(AutocompleteApp);
