import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useQuery } from "react-apollo-hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import SearchResultClassic from "./SearchResultClassic";
import { SearchProducts } from "./queries.gql";

const classes = theme => ({
  selectSection: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "93%"
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "right"
    },
    paddingRight: theme.spacing.unit * 2
  },
  formControl: {
    margin: theme.spacing.unit,
    [theme.breakpoints.up("md")]: {
      minWidth: 150
    }
  },
  searchControls: {
    minHeight: theme.spacing.unit * 5
  },
  gt200: {
    color: "#ff1111",
    fontWeight: "bold"
  },
  routing: {
    paddingLeft: 10
  },
  query: {
    color: "#ff1111",
    fontStyle: "italic",
    fontWeight: "bold"
  },
  breadcrumbs: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    }
  },
  breadcrumbWrapper: {
    [theme.breakpoints.up("md")]: {
      height: 50
    },
    paddingLeft: 0
  }
});

const sorts = {
  default: { orderField: "SCORE", orderDirection: "DESC" },
  price_asc: { orderField: "PRICE", orderDirection: "ASC" },
  price_desc: { orderField: "PRICE", orderDirection: "DESC" },
  name_asc: { orderField: "ALPHA", orderDirection: "ASC" },
  name_desc: { orderField: "ALPHA", orderDirection: "DESC" }
};

function Search({ query, classes }) {
  const [brand, selectBrand] = useState("");
  const [sort, selectSort] = useState("default");
  const { data, loading } = useQuery(SearchProducts, {
    variables: {
      q: query,
      brand: brand === "" ? null : brand,
      first: 200,
      ...sorts[sort]
    }
  });
  if (loading) {
    return <LinearProgress />;
  }
  const { searchProducts } = data;
  const edges = _.get(searchProducts, "edges", []);
  return (
    <div>
      <div>
        <Grid container direction="row" spacing={0}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              className={classes.searchControls}
              alignItems="center"
            >
              <Grid item xs={12} sm={4} className={classes.routing}>
                <div
                  className={`col-sm-12 col-md-8 text-left g-mb-5 align-self-center ${
                    classes.breadcrumbWrapper
                  }`}
                >
                  <ul
                    className="u-list-inline category-bar-textv2"
                    style={{ marginLeft: "0rem" }}
                  >
                    <li
                      className={`list-inline-item g-mr-5 ${
                        classes.breadcrumbs
                      }`}
                    >
                      <a className="g-color-main" href="https://mpparts.com">
                        Home
                      </a>
                      <i className="g-color-gray-light-v2 g-ml-5">/</i>
                    </li>
                    <li className={`list-inline-item ${classes.breadcrumbs}`}>
                      <span>Search:</span>
                    </li>
                    <li
                      className={`list-inline-item g-color-primary ${
                        classes.breadcrumbs
                      }`}
                    >
                      <span>{query}</span>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.selectSection}>
                {brand === "" ? (
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="brands">Select a Brand</InputLabel>
                    <Select
                      value={brand}
                      onChange={e => selectBrand(e.target.value)}
                      inputProps={{
                        name: "brands",
                        id: "brands"
                      }}
                    >
                      {_.map(_.get(searchProducts, "brands", []), brand => (
                        <MenuItem value={brand} key={brand}>
                          {brand}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Chip onDelete={() => selectBrand("")} label={brand} />
                )}
              </Grid>
              <Grid item xs={12} sm={4} className={classes.selectSection}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="brands">Sort By</InputLabel>
                  <Select
                    value={sort}
                    onChange={e => selectSort(e.target.value)}
                    inputProps={{
                      name: "brands",
                      id: "brands"
                    }}
                  >
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="price_asc">Price: Low to High</MenuItem>
                    <MenuItem value="price_desc">Price: High to Low</MenuItem>
                    <MenuItem value="name_asc">A - Z</MenuItem>
                    <MenuItem value="name_desc">Z - A</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          className={classes.searchResultsWrapper}
          container
          direction="row"
          justify="space-between"
        >
          {edges.length > 0 ? (
            _.map(edges, ({ node }) => (
              <SearchResultClassic key={node.prodid} node={node} />
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="display2">
                Please enter a search term.
              </Typography>
            </Grid>
          )}
        </Grid>
        {edges.length >= 200 ? (
          <Grid container direction="row" alignItems="center" justify="center">
            <Grid item>
              <Typography variant="body2" className={classes.gt200}>
                More than 200 results found. Please refine your search.
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </div>
    </div>
  );
}

Search.propTypes = {
  query: PropTypes.string,
  classes: PropTypes.object
};

export default withStyles(classes)(Search);
