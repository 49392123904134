/* global $ */

import "@babel/polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import "./index.css";
import App from "./App";
import AutocompleteApp from "./AutocompleteApp";
$.each($("[name=w]"), function(x, searchInput) {
  ReactDOM.render(
    <AutocompleteApp />,
    $(searchInput)
      .closest("form")
      .get(0)
  );
});
const searchElement = document.getElementById("search");

if (searchElement) {
  ReactDOM.render(
    <App
      query={_.get(searchElement, "dataset.query", "")}
      includeSearchInput={
        !!_.get(searchElement, "dataset.includeSearchInput", false)
      }
    />,
    searchElement
  );
}
