import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const imageBase = "https://cdn.mpparts.com/product/";

function PartImage({ node, size = 300, className = "img-fluid" }) {
  const imageUrl =
    node.imageExt !== null
      ? `${imageBase}${size}/${node.item}_${_.get(node, "imageExt", 0)
          .toString()
          .padStart(2, "0")}.jpg`
      : "//mpparts.com/assets/img/image-na.png";
  return <img alt={node.productName} className={className} src={imageUrl} />;
}

PartImage.propTypes = {
  node: PropTypes.object.isRequired,
  size: PropTypes.number,
  className: PropTypes.string
};

export default PartImage;
